<template>
  <Layout>
    <PageHeader :title="title" :items="items"></PageHeader>
    <div class="row" id="article">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row align-items-center mb-4">
              <div class="col-md-6">
                <div class="form-inline navbar-search">
                  <div class="input-group">
                    <input
                      name="title"
                      class="form-control bg-light border-0 small"
                      placeholder="Search Name"
                      aria-label="Search"
                      aria-describedby="basic-addon2"
                      value=""
                      v-model="filter"
                      v-on:keyup="search"
                    />
                    <div class="input-group-append">
                      <button
                        id="myBtn"
                        class="btn btn-search"
                        @click="searchFor"
                      >
                        <i class="fas fa-search fa-sm"> </i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="table-responsive mb-0">
              <b-table
                striped
                bordered
                :items="tableData.data"
                :fields="fields"
                :sort-by.sync="sortBy"
                :per-page="0"
                :current-page="currentPage"
                :sort-desc.sync="sortDesc"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                responsive="sm"
                class="table-bordered table-hover"
              >
                <template v-slot:cell(is_query_solved)="row">
                  <b-form-checkbox
                    switch
                    class="mb-1"
                    @change="updateQueryStatus(row.item.id)"
                    :checked="row.item.is_query_solved == 1 ? true : false"
                  >
                  </b-form-checkbox>
                </template>
                <template v-slot:cell(name)="row">
                  <span v-if="row.item.name" class="">{{ row.item.name }}</span>
                  <span v-else>---</span>
                </template>
                <template v-slot:cell(email)="row">
                  <span v-if="row.item.email" class="">{{
                    row.item.email
                  }}</span>
                  <span v-else>---</span>
                </template>
                <template v-slot:cell(message)="row">
                  <span v-if="row.item.message" class="handle-width">{{
                    row.item.message
                  }}</span>
                  <span v-else>---</span>
                </template>
                <template v-slot:cell(is_patient)="row">
                  <span v-if="row.item.is_patient == 1">
                    <b-badge variant="info"> Patient Inquiry </b-badge>
                  </span>
                  <span v-else>---</span>
                </template>
                <template v-slot:cell(doctor_name)="row">
                  <span
                    v-if="row.item.doctor_name"
                    class="d-flex flex-column justify-content-center align-items-start"
                  >
                    <span>{{ row?.item?.doctor_name }}</span>
                    <span class="my-1"
                      >+{{ row?.item?.country_code }}
                      {{ row?.item?.doctor_mobile_number }}</span
                    >
                    <b-button
                      v-if="row.item.doctor_name"
                      variant="secondary btn-sm"
                      class=""
                      :href="profileUrl + row.item.doctor_id"
                      target="_blank"
                      >View Profile
                    </b-button>
                  </span>
                  <span v-else>---</span>
                </template>
                <template v-slot:cell(mail)="row">
                  <b-button
                    v-if="row.item.doctor_name"
                    @click="openMailModal(row.item)"
                    variant="primary btn-sm"
                    >Send Mail
                  </b-button>
                  <span v-else>---</span>
                </template>
              </b-table>
            </div>
            <template v-if="tableData.total_pages > 1">
              <div class="data_paginate">
                <div
                  class="dataTables_paginate paging_simple_numbers d-flex justify-content-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <b-pagination
                      v-model="currentPage"
                      first-number
                      last-number
                      :total-rows="tableData.total"
                      :total-pages="tableData.total_pages"
                      :per-page="tableData.per_page"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </template>
          </div>
        </div>
        <b-modal
          id="bvModal"
          v-model="emailModal"
          title=""
          hide-footer
          centered
          @hidden="clearEmailModal"
        >
          <template #modal-title
            ><i
              class="mdi mdi-email-send-outline"
              style="color: #7182df; font-size: 23px"
            ></i>
            Send Mail
          </template>
          <div class="col-12">
            <b-form-group id="input-group-1" label-for="input-1" class="col-12">
              <label style="font-weight: 500"
                >Email <span style="color: red">*</span></label
              >
              <b-form-input
                id="input-1"
                placeholder="Enter email"
                v-model="emailform.email"
                :class="{
                  'is-invalid': submitted && $v.emailform.email.$error,
                }"
              ></b-form-input>
              <div
                v-if="submitted && !$v.emailform.email.required"
                class="invalid-feedback"
              >
                email is required.
              </div>
              <div
                v-if="submitted && !$v.emailform.email.email"
                class="invalid-feedback"
              >
                Enter a valid email.
              </div>
            </b-form-group>
            <b-form-group id="input-group-1" label-for="input-1" class="col-12">
              <label style="font-weight: 500"
                >Message <span style="color: red">*</span></label
              >
              <b-form-textarea
                id="message"
                v-model="emailform.message"
                placeholder="Enter Message"
                rows="4"
                :class="{
                  'is-invalid': submitted && $v.emailform.message.$error,
                }"
              >
              </b-form-textarea>
              <div
                v-if="submitted && !$v.emailform.message.required"
                class="invalid-feedback"
              >
                message is required.
              </div>
            </b-form-group>
            <div class="col-12 d-flex justify-content-end">
              <b-button
                type="submit"
                variant="success btn-sm"
                @click.prevent="sendMail"
                >Send Mail
              </b-button>
            </div>
          </div>
        </b-modal>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import MixinRequest from "../../../mixins/request";
import patientQueryMixin from "../../../mixins/ModuleJs/patient-query";
import "viewerjs/dist/viewer.css";
import VueViewer from "v-viewer";
import Vue from "vue";
Vue.use(VueViewer);
import { required, email } from "vuelidate/lib/validators";

export default {
  mixins: [MixinRequest, patientQueryMixin],
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Patient Query",
      items: [
        {
          text: "Home",
          href: "/",
        },
        {
          text: "List",
        },
      ],
    };
  },
  methods: {
    show(img) {
      this.$viewerApi({
        images: [img],
      });
    },
  },
  validations: {
    emailform: {
      email: { required, email },
      message: { required },
    },
  },
};
</script>
